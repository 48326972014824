import styles from './SocialSharingModal.module.scss'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import travelObject from '@/entities/travel/interface'
import { Col, Modal, Row } from 'react-bootstrap'
import Icon from '@/components/elements/Icon'
import EmailIcon from '@mui/icons-material/Email'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LinkIcon from '@mui/icons-material/Link'
import CheckIcon from '@mui/icons-material/Check'
import { isMobile } from 'react-device-detect'
import { sendShareEvent } from '@/lib/ga/gtag'
import { SocialSharingMethod } from '@/interfaces'

interface Props {
  travel: travelObject
  triggerElement: JSX.Element
}

const SocialSharingModal = ({ travel, triggerElement }: Props) => {
  const { t } = useTranslation('common')
  const [show, setShow] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setLinkCopied(false)
    setShow(true)
  }

  const url = process.env.NEXT_PUBLIC_BASE_URL + '/tour/' + travel.seoSettings.urlSlug
  const urlEncoded = encodeURIComponent(url)

  const sendEvent = (name: SocialSharingMethod) => {
    sendShareEvent({ method: name, itemId: `${travel.id}` })
  }

  interface Platform {
    name: SocialSharingMethod
    label: string
    icon: JSX.Element
    url: string
    onClick?: (event: any) => void
  }
  const platforms: Platform[] = [
    {
      name: 'email',
      label: t('socialSharing.sendEmail'),
      icon: <EmailIcon className={styles.icon} />,
      url: `mailto:?subject=${travel.name} | ${process.env.NEXT_PUBLIC_SITE_NAME}&body=${url}`,
    },
    {
      name: 'copy_link',
      label: t('socialSharing.copyLink'),
      icon: <>{!linkCopied ? <LinkIcon className={styles.icon} /> : <CheckIcon className={styles.icon} />}</>,
      onClick: (event: any) => {
        event.preventDefault()
        // event.stopPropagation()
        navigator.clipboard.writeText(url)
        setLinkCopied(true)
      },
      url: '#',
    },
    {
      name: 'whatsapp',
      label: 'WhatsApp',
      icon: <WhatsAppIcon className={styles.icon} />,
      url: isMobile ? 'whatsapp://send?text=' + urlEncoded : 'https://web.whatsapp.com/send?text=' + urlEncoded,
    },
    {
      name: 'facebook',
      label: 'Facebook',
      icon: <Icon name={'facebook'} className={styles.icon} />,
      url: 'https://www.facebook.com/sharer/sharer.php?u=' + urlEncoded,
    },
    {
      name: 'twitter',
      label: 'Twitter',
      icon: <TwitterIcon className={styles.icon} />,
      url: 'https://twitter.com/intent/tweet?url=' + urlEncoded,
    },
  ]

  return (
    <>
      {React.cloneElement(triggerElement, { onClick: handleShow })}
      <Modal size={'lg'} show={show} onHide={handleClose} className={styles.modal} centered scrollable={false}>
        <button type="button" className={styles.close} aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Body className={styles.body}>
          <div className={styles.heading}>
            <Modal.Title className={styles.title}>{t('socialSharing.title')}</Modal.Title>
            <p>{t('socialSharing.shareNow')}</p>
          </div>
          <Row className={'justify-content-center mt-3 mb-4'}>
            {platforms.map((platform, index: number) => {
              let onClick = (event: any) => {
                sendEvent(platform.name)
              }
              if (platform.onClick) {
                onClick = (event: any) => {
                  sendEvent(platform.name)
                  // @ts-ignore
                  platform.onClick(event)
                }
              }
              return (
                <Col
                  xs={'auto'}
                  as={'a'}
                  className={styles.sharingLink}
                  href={platform.url}
                  target={'_blank'}
                  rel="noreferrer"
                  key={index}
                  onClick={onClick}
                >
                  <span className={styles.iconWrap}>{platform.icon}</span>
                  <span className={styles.label}>{platform.label}</span>
                </Col>
              )
            })}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SocialSharingModal
