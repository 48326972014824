import { Col, Container, Row } from 'react-bootstrap'
import NewsletterForm from '@/components/models/newsletter/NewsletterForm'

interface Props {
  classNames?: string
}

const NewsletterBanner = ({ classNames }: Props) => {
  return (
    <Container fluid className={`bg-tertiary an-wobble-horizontal-trigger ${classNames}`}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={9} xl={7} className="my-5">
            <NewsletterForm />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default NewsletterBanner
