import styles from './TravelHighlightsList.module.scss'
import travelHighlightObject from '@/entities/travelHighlight/interface'
import { useTranslation } from 'next-i18next'

interface Props {
  highlights: travelHighlightObject[]
}

const TravelHighlightsList = ({ highlights }: Props) => {
  const { t } = useTranslation('common')

  return (
    <>
      {highlights.length > 0 && (
        <>
          <div className={styles.title}>{t('travel.travelHighlights')}</div>
          <ul className={styles.list}>
            {highlights.map((highlight, index: number) => {
              return <li key={index}>{highlight.text}</li>
            })}
          </ul>
        </>
      )}
    </>
  )
}

export default TravelHighlightsList
