import travelObject from '@/entities/travel/interface'
import { TFunction } from 'next-i18next'
import { PageNavItem } from '@/components/models/travel/navs/PageNavigation/types'
import navItemsHook from '@/components/models/travel/navs/PageNavigation/functions/navItemsHook'

const pageNavItems = (travel: travelObject, t: TFunction): PageNavItem[] => {
  const items = []
  // guides
  if (travel.travelGuides.length) {
    items.push({ name: 'guides', label: t('travel.travelGuides'), href: '#guides' })
  }

  // teams
  if (travel.teams.length) {
    items.push({ name: 'teams', label: t(!travel.travelGuides.length ? 'travel.travelGuides' : 'travel.teams'), href: '#teams' })
  }

  // itinerary & extensions
  if (hasProgram(travel)) {
    items.push({ name: 'itinerary', label: t('travel.itinerary.program'), href: '#itinerary' })
  }

  // accommodations
  if (travel.accommodationAssignments.length) {
    items.push({
      name: 'accommodation',
      label: t(travel.accommodationAssignments.length == 1 ? 'travel.accommodation' : 'travel.accommodations'),
      href: '#accommodation',
    })
  }

  // pricing
  items.push({ name: 'pricing', label: t('travel.pricesAndServices'), href: '#pricing' })

  // gallery
  if (travel.gallery.images.length) {
    items.push({ name: 'gallery', label: t('travel.gallery'), href: '#gallery' })
  }

  // destinations
  if (travel.destinations.length) {
    items.push({ name: 'destinations', label: t('travel.countryInformation'), href: '#destinations' })
  }

  return navItemsHook(items)
}

const hasProgram = (travel: travelObject) => {
  switch (travel.programType) {
    case 'TEXT':
      if (travel.programText) {
        return true
      }
      break
    case 'ITINERARY':
      if (travel.itinerary.items.length > 0) {
        return true
      }
      break
  }
  return false
}

export default pageNavItems
