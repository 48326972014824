import { AxiosResponse } from 'axios'
import { useEffect, useRef, useState } from 'react'

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>
  readonly preDownloading?: () => void
  readonly postDownloading?: () => void
  readonly urlCreator?: (data: Blob) => string
  readonly onError: () => void
  readonly getFileName: () => string
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
  readonly name: string | undefined
  readonly url: string | undefined
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading = () => {},
  postDownloading = () => {},
  urlCreator = (data: Blob) => URL.createObjectURL(new Blob([data])),
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const [url, setFileUrl] = useState<string>()
  const [name, setFileName] = useState<string>()

  useEffect(() => {
    if (url) {
      ref.current?.click()
      URL.revokeObjectURL(url)
    }
  }, [url]) // Dies wird ausgeführt, wenn sich die `url` ändert

  const download = async () => {
    try {
      preDownloading()
      const { data } = await apiDefinition()
      const url = urlCreator(data)
      setFileUrl(url)
      setFileName(getFileName())
      // ref.current?.click()
      postDownloading()
      // URL.revokeObjectURL(url)
    } catch (error) {
      onError()
    }
  }

  return { download, ref, url, name }
}
