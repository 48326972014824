import styles from './BookingSidebar.module.scss'
import { useState } from 'react'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelObject from '@/entities/travel/interface'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ArrowIcon from '@/components/elements/icons/ArrowIcon'
import { useTranslation } from 'next-i18next'
import TravelHighlightsList from '@/components/models/travel/highlights/TravelHighlightsList'
import dynamic from 'next/dynamic'
import { formatPrice } from '@/lib/helpers/helpers'
import BookingSidebarBottomContent from '@/components/models/booking/BookingSidebar/BookingSidebarBottomContent'
const InitBooking = dynamic(() => import('@/components/models/booking/InitBooking'), { ssr: false })

interface Props {
  travel: travelObject
  cmsConfig: cmsConfigObject
}

const BookingSidebar = ({ travel, cmsConfig }: Props) => {
  const { t } = useTranslation('common')
  const [mobileBookingOpen, setMobileBookingOpen] = useState(false)

  const handleOpen = () => {
    setMobileBookingOpen(true)
  }
  return (
    <>
      <Col as={'aside'} xs={'auto'} className={`d-lg-block ${styles.root} ${mobileBookingOpen ? 'd-block' : 'd-none'}`}>
        <div className={styles.sidebarContent}>
          <InitBooking
            contactBlockSettings={cmsConfig.contactBlockSettings}
            setMobileOpen={setMobileBookingOpen}
            colorTheme={'dark'}
            navType={'lead'}
          />
          <BookingSidebarBottomContent />
          <Container>
            <Row className={'m-0'}>
              <Col>
                <div className="mt-5">
                  <TravelHighlightsList highlights={travel.highlights} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
      <Container className={`${styles.mobileBar} d-block d-lg-none`}>
        <Row className={'justify-content-between align-items-center my-2 '}>
          <Col xs={'auto'} className={styles.info}>
            <div>{t('booking.pricePerPerson')}</div>
            <div className={styles.price}>
              <span className={styles.from}>{t('booking.priceFrom')}&nbsp;</span>
              <span className={styles.price}>{formatPrice(travel.minPrice, travel.currency)}</span>
            </div>
          </Col>
          <Col xs={'auto'} className={'ps-0'}>
            <Button variant={'signal'} className="an-wobble-horizontal-trigger fs-5 text-uppercase" onClick={handleOpen}>
              <ArrowIcon fontsize={'5rem'} className={'me-2'} />
              <span className={'d-none d-sm-inline-block'}>{t('booking.bookNow')}</span>
              <span className={'d-sm-none'}>{t('booking.book')}</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default BookingSidebar
