import styles from './PageNavigation.module.scss'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Icon from '@/components/elements/Icon'
import { useTranslation } from 'next-i18next'
import travelObject from '@/entities/travel/interface'
import SocialSharingModal from '@/components/models/travel/SocialSharingModal'
import { useState } from 'react'
import pageNavItems from '@/components/models/travel/navs/PageNavigation/functions/pageNavItems'
import { useDownloadFile } from '@/hooks/useDownloadFile'
import axios from 'axios'

interface Props {
  travel: travelObject
}

const PageNavigation = ({ travel }: Props) => {
  const { t } = useTranslation('common')

  const iconSize = 1.8
  const items = pageNavItems(travel, t)

  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed)
  }

  // PDF Generator
  const [pdfIsLoading, setPdfIsLoading] = useState(false)

  const generatePdf = (travel: travelObject) => {
    return axios.get(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/rest/travel/pdf/download?slug=${travel.seoSettings.urlSlug}&tenantId=${process.env.NEXT_PUBLIC_TENANT_ID}`,
      { responseType: 'blob' }
    )
  }

  const onErrorGeneratePdf = () => {
    setPdfIsLoading(false)
    alert('Fehler beim Download')
  }

  const getFileName = (travel: travelObject) => {
    return `${travel.seoSettings.urlSlug}.pdf`
  }

  const { ref, url, download, name } = useDownloadFile({
    preDownloading: () => setPdfIsLoading(true),
    apiDefinition: () => generatePdf(travel),
    postDownloading: () => setPdfIsLoading(false),
    onError: onErrorGeneratePdf,
    getFileName: () => getFileName(travel),
    urlCreator: (data: Blob) => {
      return URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    },
  })

  const handleGeneratePdf = async () => {
    await download()
  }

  return (
    <Container fluid className={styles.root}>
      <Row>
        <Col>
          <Navbar expand="xxl" className={styles.navbar}>
            {/*<Navbar.Toggle aria-controls="travel-page-nav" />*/}
            <button
              aria-controls="travel-page-nav"
              type="button"
              aria-label="Toggle navigation"
              className={`navbar-toggler collapsed`}
              aria-expanded={!isNavCollapsed}
              onClick={handleNavCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/*<Navbar.Collapse id="travel-page-nav">*/}
            {/*<div className="navbar-collapse collapse show" id="travel-page-nav">*/}
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="travel-page-nav">
              <Nav className={styles.nav}>
                {items.map((item, index: number) => (
                  <Nav.Link href={item.href} key={index} className={styles.navLink} onClick={handleNavCollapse}>
                    {item.label}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
            {/*</Navbar.Collapse>*/}
          </Navbar>

          <div className={styles.iconLinks}>
            {/*<a href="#" className={`${styles.iconLink} ${styles.iconLike}`} aria-label="Merkliste">*/}
            {/*  <Icon name="like" size={iconSize} />*/}
            {/*</a>*/}
            {travel.travelPdf.url ? (
              <a href={travel.travelPdf.url} className={styles.iconLink} aria-label="Download PDF" target="_blank" rel="noreferrer">
                <div className={styles.icon}>
                  <Icon name="download" size={iconSize} />
                </div>
                <div className={styles.label}>{t('travel.interactions.downloadPdf')}</div>
              </a>
            ) : (
              <>
                <a href={url} download={name} className="hidden" ref={ref} />
                <div onClick={handleGeneratePdf} className={styles.iconLink} aria-label="Download PDF">
                  <div className={styles.icon}>
                    <Icon name="download" size={iconSize} />
                  </div>
                  <div className={styles.label}>{t('travel.interactions.downloadPdf')}</div>
                  <div className={styles.spinnerWrap}>
                    <div
                      className={`spinner-border spinner-border-sm text-secondary ${styles.loadingSpinner} ${pdfIsLoading ? styles.isLoading : ''}`}
                      role="status"
                    />
                  </div>
                </div>
              </>
            )}
            <SocialSharingModal
              travel={travel}
              triggerElement={
                <div className={styles.iconLink}>
                  <div className={styles.icon}>
                    <Icon name="share" size={iconSize} />
                  </div>
                  <div className={styles.label}>{t('travel.interactions.share')}</div>
                </div>
              }
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PageNavigation
