import styles from '@/components/pages/TravelPage/TravelPage.module.scss'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelObject from '@/entities/travel/interface'
import { Container, Row } from 'react-bootstrap'
import HeaderBlock from '@/components/models/travel/HeaderBlock'
import PageNavigation from '@/components/models/travel/navs/PageNavigation'
import IntroBlock from '@/components/models/travel/IntroBlock'
import TravelGuideBlock from '@/components/models/travelGuide/TravelGuideBlock'
import ItineraryBlock from '@/components/models/itinerary/ItineraryBlock'
import PricingBlock from '@/components/models/travel/PricingBlock'
import AccommodationBlock from '@/components/models/accommodation/AccommodationBlock'
import TeamsBlock from '@/components/models/travelGuide/TeamsBlock'
import GalleryBlock from '@/components/models/travel/GalleryBlock'
import DestinationsBlock from '@/components/models/travel/DestinationsBlock'
import NewsletterBanner from '@/components/models/newsletter/NewsletterBanner'
import BestsellerSliderBlock from '@/components/models/cms/blocks/BestsellerSliderBlock'
import ContactBannerBlock from '@/components/models/cms/blocks/ContactBannerBlock'
import BookingSidebar from '@/components/models/booking/BookingSidebar'
import TestimonialsBlock from '@/components/models/travel/TestimonialsBlock'

interface TravelPageProps {
  travel: travelObject
  cmsConfig: cmsConfigObject
}

const TravelPage = ({ travel, cmsConfig }: TravelPageProps) => {
  return (
    <>
      {/* Header */}
      <HeaderBlock travel={travel} />

      <section id={'content'}>
        <Container fluid>
          <Row>
            <div className={styles.content}>
              <PageNavigation travel={travel} />
              <IntroBlock travel={travel} />
              <TravelGuideBlock travelGuides={travel.travelGuides} />
              <TeamsBlock teams={travel.teams} />
              <ItineraryBlock travel={travel} />
              <AccommodationBlock accommodationAssignments={travel.accommodationAssignments} />
              <PricingBlock travel={travel} />
              <GalleryBlock gallery={travel.gallery} />
              <TestimonialsBlock testimonials={travel.testimonials} />
              <DestinationsBlock destinations={travel.destinations} />
            </div>

            <BookingSidebar travel={travel} cmsConfig={cmsConfig} />
          </Row>
        </Container>

        {travel.travelConsultant && (
          <ContactBannerBlock
            block={{ employee: travel.travelConsultant, phone: travel.travelConsultant?.phone ? travel.travelConsultant.phone : '', email: '' }}
            cmsConfig={cmsConfig}
            className={'mt-0'}
          />
        )}

        <BestsellerSliderBlock />
        {/*<TravelSliderBanner title={t('justAsGood')} travels={travels} />*/}
        <NewsletterBanner />
      </section>
    </>
  )
}

export default TravelPage
